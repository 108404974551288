const  getFutureTimestamp = (minutes : number) => {
  return Date.now() + minutes * 60 * 1000;
}
export default defineNuxtRouteMiddleware((to, from) => {


  let urlParams: URLSearchParams = new URLSearchParams(from.hash);

  if (urlParams.size > 0){
    const credentials : any = {};

    urlParams.forEach((value, key) => {
      credentials[key.replace('#', '')] = value;
    });

    if (credentials.access_token){
      const expiresIn = getFutureTimestamp(15);

      let  authSessionCookie;
      try {
        // Set the session expiry in a secure cookie
        authSessionCookie = useCookie('authSession', {
          maxAge: expiresIn,
          secure: true, // Only send over HTTPS
          sameSite: 'strict',
        });

      } catch (e) {
        console.log('failed to persist cookie: ', e);
      }

      const idSessionCookie = useCookie('idSession', {
        maxAge: expiresIn,
        secure: true, // Only send over HTTPS
        sameSite: 'strict',
      });

      localStorage.setItem('idSession', credentials.id_token);
      localStorage.setItem('authSession', credentials.access_token);

      authSessionCookie.value = credentials.access_token;
      idSessionCookie.value = credentials.id_token;
    }

  }

  if (!to.path.startsWith('/members')) {
    return;
  }


  let sessionExpiry : string | number | null = useCookie('_vg_sessionExpiry').value;
  const currentTime : number = Date.now();

  if (sessionExpiry === undefined || sessionExpiry === null ) {
    sessionExpiry = Date.now() - 5;
  }
  console.log('checking login session - now: ', currentTime, ' expiry: ', sessionExpiry);

  const timeRemaining = sessionExpiry - currentTime;

  if (timeRemaining <= 0) {
    console.log("Expired");
  }

  const seconds = Math.floor((timeRemaining / 1000) % 60);
  const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
  const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

  console.log(`${days}d ${hours}h ${minutes}m ${seconds}s`);

  if (sessionExpiry && currentTime > sessionExpiry) {
    console.log('session expired');
    useCookie('_vg_sessionExpiry').value = null;
    useCookie('idSession').value = null;
    useCookie('authSession').value = null;


    if (to.path !== '/account') {
      return navigateTo('/logout?action=true'); // or Auth0 logout endpoint
    }

  }
});
